// autogenerated
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {CustomerServiceType, DeliveryDayOfWeek} from "@/models/Enums";
import CustomerGroup from "@/models/server/CustomerGroup";
import CustomerContact from "@/models/server/CustomerContact";
import CustomerCode from "@/models/server/CustomerCode";
import CustomerCalendarDate from "@/models/server/CustomerCalendarDate";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";
import AittaConstants from "@/helpers/AittaConstants";

export default class Customer extends CustomerMobileInfo {

    public customerGroup: CustomerGroup | null = null;

    public notes: string | null = null;

    public comments: string | null = null;

    public info: string | null = null;

    public deliveryStartDate: Date = new Date();

    public deliveryEndDate: Date | null = null;

    public consumptionInterval: number = 0;

    public closeInterval: number = 0;

    public contactPerson: string | null = null;

    public contacts: CustomerContact[] | null = null;

    public codes: CustomerCode[] | null = null;

    public locationId: string | null = null;

    public externalAddress: string | null = null;

    public isVirtualAddress: boolean = false;

    public ref: string | null = null;

    public refFirstName: string | null = null;

    public refLastName: string | null = null;

    public deleted: boolean = false;

    public deletedAt: Date | null = null;

    public createdAt: Date = new Date();

    public calendarDates: CustomerCalendarDate[] | null = null;

    public calendarWarning: boolean | null = null;

    public selectionMeeting: Date | null = null;

    public readonly isCustomer: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof Customer) || ((from as any).isCustomer === true));
    }

    public static as(from?: object | null): Customer | null {
        return (Customer.is(from)) ? from as Customer : null;
    }

    // #region Placeholder

    public static create(): Customer {
        const item = new Customer();
        item.deliveryStartDate = Utility.today();
        item.deliveryInterval = AittaConstants.initialDeliveryInterval;
        item.closeInterval = AittaConstants.initialCloseInterval;
        item.deliveryDay = DeliveryDayOfWeek.Friday;
        return item;
    }

    public static group(customers: Customer[], excludeSubscriptionCustomers: boolean = false): (CustomerGroup | Customer)[] {

        const items: (CustomerGroup | Customer)[] = [];

        const customerGroups: CustomerGroup[] = customers
            .map(item => item.customerGroup!)
            .distinct(item => item.id);

        customerGroups.sortBy(item => item.name);

        for (let i: number = 0; i < customerGroups.length; i++) {
            const group: CustomerGroup = customerGroups[i];
            items.push(group);
            
            const groupCustomers: Customer[] = customers.where(item => (item.customerGroupId == group.id) && ((!excludeSubscriptionCustomers) || (item.serviceType != CustomerServiceType.Subscription)));
            
            items.push(...groupCustomers);
        }

        return items;
    }

    public static getCustomerGroups(customers: Customer[]): CustomerGroup[] {
        return customers
            .where(item => item.customerGroup != null)
            .map(item => item.customerGroup!)
            .distinct(item => item.id);
    }

    public static isValidCustomer(customer: Customer, validateCalendar: boolean = true): boolean {
        return (
            (!!customer.name) &&
            (!!customer.code) &&
            ((!!customer.location) || (!!customer.virtualAddress)) &&
            ((!validateCalendar) || (this.isValidCustomerCalendar(customer)))
        )
    }

    public static isValidCustomerCalendar(customer: Customer): boolean {
        return (
            (!Customer.supportsCalendar(customer)) ||
            (
                (!!customer.deliveryInterval) &&
                (!!customer.closeInterval) &&
                (customer.deliveryInterval >= 1) &&
                (customer.deliveryInterval <= Customer.maxDeliveryInterval(customer)) &&
                (customer.closeInterval >= 1) &&
                (customer.closeInterval <= Customer.maxCloseInterval(customer))
            )
        );
    }

    public static supportsCalendar(customer: Customer): boolean {
        return AittaConstants.calendarServiceTypes.includes(customer.serviceType);
    }

    public static getNameWithCode(model: Customer): string {
        const name: string = model.name;
        const code: string = model.codeInfo;
        return ((name.includes(code)) && (name != code))
            ? name.replace(code, `<mark>${code}</mark>`)
            : `<mark>${code}</mark> ${name}`
    }

    public static addCode(customer: Customer, code: CustomerCode): void {
        customer.codes = customer.codes ?? [];
        customer.codes.push(code);
    }

    public static deleteCode(customer: Customer, code: CustomerCode): number {
        customer.codes = customer.codes ?? [];
        const index: number = customer.codes.findIndex(item => item.id == code.id);

        if (index != -1) {
            customer.codes.removeAt(index);
        }

        return index;
    }

    // #endregion
}