import React from "react";
import {BaseComponent, ch, PageCacheProvider} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonContainer,
    ButtonType,
    Checkbox,
    DateInput,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    DropdownRequiredType,
    DropdownVerticalAlign,
    Form,
    IconSize,
    Modal,
    ModalSize,
    SelectListItem,
    Spinner,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import Product from "@/models/server/Product";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import Customer from "@/models/server/Customer";
import ListProductsRequest from "@/models/server/requests/ListProductsRequest";
import CustomerGroup from "@/models/server/CustomerGroup";
import {ProductReplacementLevel, ProductReplacementType} from "@/models/Enums";
import EnumProvider from "@/providers/EnumProvider";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./ProductReplacementsModal.module.scss";

export interface IProductReplacementsModalProps {
    onSaveAsync(replacementType: ProductReplacementType, replacementLevel: ProductReplacementLevel, generateLabels: boolean, customerGroupId: string | null, customerId: string | null, fromId: string, toId: string, expirationDate: Date | null, deleteGroupAssortments: boolean): Promise<void>;
}

interface IProductReplacementsModalState {
    replacementType: ProductReplacementType;
    replacementLevel: ProductReplacementLevel;
    generateLabels: boolean;
    includeDeletedProducts: boolean;
    selectedCustomer: Customer | null;
    selectedCustomerGroup: CustomerGroup | null;
    customers: Customer[];
    productsFrom: Product[];
    productsTo: Product[];
    fromProduct: Product | null;
    toProduct: Product | null;
    expirable: boolean,
    expirationDate: Date | null;
    loading: boolean;
    deleteGroupAssortments: boolean;
}

export default class ProductReplacementsModal extends BaseComponent<IProductReplacementsModalProps, IProductReplacementsModalState> {

    state: IProductReplacementsModalState = {
        replacementType: ProductReplacementType.Temporary,
        replacementLevel: ProductReplacementLevel.Global,
        generateLabels: true,
        includeDeletedProducts: false,
        selectedCustomer: null,
        selectedCustomerGroup: null,
        customers: [],
        productsFrom: [],
        productsTo: [],
        fromProduct: null,
        toProduct: null,
        expirable: false,
        expirationDate: null,
        loading: false,
        deleteGroupAssortments: false
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _dateInputRef: React.RefObject<DateInput> = React.createRef();

    private async onClose(): Promise<void> {
        await this._modalRef.current!.closeAsync();
    }

    private async selectCustomerGroupOrCustomerAsync(customerGroupOrCustomer: CustomerGroup | Customer | null): Promise<void> {
        this.state.toProduct = null;
        this.state.fromProduct = null;

        this.state.selectedCustomerGroup = (this.replacementLevel == ProductReplacementLevel.CustomerGroup) ? customerGroupOrCustomer as CustomerGroup : null;
        this.state.selectedCustomer = (this.replacementLevel == ProductReplacementLevel.Customer) ? customerGroupOrCustomer as Customer : null;

        this.state.deleteGroupAssortments = (this.state.selectedCustomer == null);
            
        await this.setState({
            selectedCustomer: this.state.selectedCustomer,
            selectedCustomerGroup: this.state.selectedCustomerGroup,
            deleteGroupAssortments: this.state.deleteGroupAssortments,
        });

        await this.fetchProductsAsync();
    }

    private async fetchCustomersAsync(): Promise<Customer[]> {
        return await PageCacheProvider.getAsync("listCustomers", () => this.postAsync("/api/productManagement/listCustomers"));
    }

    private async fetchProductsAsync(): Promise<void> {
        const request = new ListProductsRequest();

        request.excludeDeleted = true;
        request.includeInfo = false;
        request.excludeDeleted = (!this.includeDeletedProducts);

        await this.setState({loading: true});

        const products: Product[] = await this.postAsync("/api/productManagement/listProducts", request);

        let productsFrom: Product[];
        if (this.selectedCustomerGroupId || this.selectedCustomerId) {
            request.customerGroupId = this.selectedCustomerGroupId;
            request.customerId = this.selectedCustomerId;

            productsFrom = await this.postAsync("/api/productManagement/listProducts", request);
        } else {
            productsFrom = products;
        }

        const productsTo: Product[] = products.where(item => !item.deleted);
        
        await this.setState({productsFrom: productsFrom, productsTo: productsTo, loading: false});
    }

    private async onSaveAsync(): Promise<void> {
        const confirmation: string = (this.replacementLevel == ProductReplacementLevel.Global)
            ? Localizer.productReplacementPageModalGlobalReplacementConfirmation.format(this.fromProduct!.code, this.toProduct!.code)
            : (this.replacementLevel == ProductReplacementLevel.CustomerGroup)
                ? Localizer.productReplacementPageModalGroupReplacementConfirmation.format(this.state.selectedCustomerGroup?.name, this.fromProduct!.code, this.toProduct!.code)
                : Localizer.productReplacementPageModalCustomerReplacementConfirmation.format(this.state.selectedCustomer?.name, this.state.selectedCustomer?.codeInfo, this.fromProduct!.code, this.toProduct!.code);

        const confirmed: boolean = await ch.confirmAsync(confirmation);

        if (!confirmed) {
            return;
        }

        await this.props.onSaveAsync(this.replacementType, this.replacementLevel, this.generateLabels, this.selectedCustomerGroupId, this.selectedCustomerId, this.fromProduct!.id, this.toProduct!.id, this.expireDate, this.deleteGroupAssortments);

        await this.onClose();
    }

    public get loading(): boolean {
        return this.state.loading;
    }

    public get expirable(): boolean {
        return this.state.expirable;
    }

    public get generateLabels(): boolean {
        return this.state.generateLabels;
    }

    public get includeDeletedProducts(): boolean {
        return this.state.includeDeletedProducts;
    }

    public get deleteGroupAssortments(): boolean {
        return this.state.deleteGroupAssortments;
    }

    public get fromProduct(): Product | null {
        return this.state.fromProduct;
    }

    public get toProduct(): Product | null {
        return this.state.toProduct;
    }

    public get selectedCustomerId(): string | null {
        return this.state.selectedCustomer?.id || null;
    }

    public get selectedCustomerGroupId(): string | null {
        return this.state.selectedCustomerGroup?.id || null;
    }

    public get productsFrom(): Product[] {
        return (!this.loading)
            ? this.state.productsFrom
            : [];
    }

    public get productsTo(): Product[] {
        return (!this.loading)
            ? this.state.productsTo
            : [];
    }

    public get customerItems(): CustomerGroup[] | Customer[] {
        return (this.replacementLevel == ProductReplacementLevel.CustomerGroup)
            ? this.state.customers
                .map(item => item.customerGroup!)
                .distinct(item => item.id)
            : this.state.customers;
    }

    public get customerItem(): CustomerGroup | Customer | string | null {
        return (this.replacementLevel == ProductReplacementLevel.CustomerGroup)
            ? this.state.selectedCustomerGroup
            : (this.replacementLevel == ProductReplacementLevel.Customer)
                ? this.state.selectedCustomer
                : null;
    }

    public get expireDate(): Date | null {
        return (this.expirable)
            ? this.state.expirationDate
            : null;
    }

    public get replacementType(): ProductReplacementType {
        return this.state.replacementType;
    }

    public get replacementLevel(): ProductReplacementLevel {
        return this.state.replacementLevel;
    }

    public get customerGroupLabel(): string {
        return (this.replacementLevel == ProductReplacementLevel.Global)
            ? Localizer.productReplacementPageModalGlobalSelectionLabel
            : (this.replacementLevel == ProductReplacementLevel.CustomerGroup)
                ? Localizer.productReplacementPageModalGroupSelectionLabel
                : Localizer.productReplacementPageModalCustomerSelectionLabel;
    }

    public get subTitle(): string {
        return (this.replacementLevel == ProductReplacementLevel.CustomerGroup)
            ? Localizer.productReplacementPageModalCustomerGroup.format(this.state.selectedCustomerGroup?.name)
            : (this.state.selectedCustomer != null)
                ? Localizer.productReplacementPageModalCustomer.format(this.state.selectedCustomer.name, this.state.selectedCustomer.codeInfo)
                : Localizer.productReplacementPageModalGlobal;
    }

    public async setGenerateLabels(generateLabels: boolean): Promise<void> {
        await this.setState({generateLabels});
    }

    public async setIncludeDeletedProducts(includeDeletedProducts: boolean): Promise<void> {
        await this.setState({includeDeletedProducts});

        await this.fetchProductsAsync();
    }

    public async setDeleteGroupAssortments(deleteGroupAssortments: boolean): Promise<void> {
        await this.setState({deleteGroupAssortments});
    }

    public async setExpirableAsync(value: boolean): Promise<void> {
        if (!value) {
            this.state.expirationDate = null;
        } else {
            this.state.expirationDate = this._dateInputRef?.current?.value || null;
        }

        await this.setState({expirable: value});
    }

    public async setExpirationDateAsync(value: Date): Promise<void> {
        await this.setState({expirationDate: value});
    }

    public validateProductSelection(): string | null {
        return (this.fromProduct!.id == this.toProduct!.id)
            ? Localizer.productReplacementPageModalSelectDifferentProducts
            : null;
    }

    public async setProductAsync(item: Product | null, toProduct: boolean): Promise<void> {
        if (toProduct) {
            await this.setState({toProduct: item});
        } else {
            await this.setState({fromProduct: item});
        }
    }

    private async selectReplacementTypeFilterAsync(replacementType: ProductReplacementType, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            if (replacementType == ProductReplacementType.Permanent) {
                this.state.expirable = false;
                this.state.expirationDate = null;
                this.state.generateLabels = true;
                this.state.deleteGroupAssortments = true;
            } else {
                this.state.includeDeletedProducts = false;
                this.state.deleteGroupAssortments = false;
            }

            await this.setState({replacementType});

            await this.fetchProductsAsync();
        }
    }

    private async selectReplacementLevelFilterAsync(item: SelectListItem | null, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            const replacementLevel: number = parseInt(item!.value)

            await this.setState({replacementLevel});

            if (replacementLevel == ProductReplacementLevel.Global) {
                await this.selectCustomerGroupOrCustomerAsync(null);
            }
        }
    }

    public async openAsync(replacementLevel: ProductReplacementLevel, customerGroup: CustomerGroup | null, customer: Customer | null): Promise<void> {
        const selectedCustomerGroup: CustomerGroup | null = (replacementLevel == ProductReplacementLevel.CustomerGroup) ? customerGroup : null;
        const selectedCustomer: Customer | null = (replacementLevel == ProductReplacementLevel.Customer) ? customer : null;

        const reload: boolean = (
            (this.state.productsFrom.length == 0) ||
            (this.state.productsTo.length == 0) ||
            (selectedCustomerGroup?.id != this.selectedCustomerGroupId) ||
            (selectedCustomer?.id != this.selectedCustomerId)
        );

        this.state.replacementType = ProductReplacementType.Temporary;
        this.state.generateLabels = true;
        this.state.replacementLevel = replacementLevel;
        this.state.selectedCustomer = selectedCustomer;
        this.state.selectedCustomerGroup = selectedCustomerGroup;

        this.state.fromProduct = null;
        this.state.toProduct = null;
        this.state.expirable = false;
        this.state.expirationDate = null;

        await this._modalRef.current?.openAsync();

        if (reload) {
            await this.fetchProductsAsync();
        } else {
            await this.reRenderAsync();
        }
    }

    public async closeAsync(): Promise<void> {
        await this._modalRef.current?.closeAsync();

        await this.reRenderAsync();
    }

    public get isOpen(): boolean {
        return (this._modalRef.current != null) && (this._modalRef.current.isOpen);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.setState({loading: true});

        const customers: Customer[] = await this.fetchCustomersAsync();

        await this.setState({customers, loading: false});
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        const showDeleteGroupAssortmentsCheckbox: boolean = (
            (this.replacementType == ProductReplacementType.Permanent) &&
            (this.replacementLevel != ProductReplacementLevel.Customer)
        );
        
        return (
            <Modal notResponsive
                   id={this.id}
                   ref={this._modalRef}
                   className={this.css(styles.productReplacementsModal)}
                   title={Localizer.productReplacementPageModalNewReplacement}
                   subtitle={this.subTitle}
                   size={ModalSize.Large}
                   onClose={() => this.onClose()}
            >
                {
                    (this.isOpen) &&
                    (
                        <div className={styles.content}>

                            <Form className={styles.form} onSubmit={() => this.onSaveAsync()}>

                                <TwoColumns>

                                    <Dropdown id="replacemenTypeFilter" required noWrap
                                              label={Localizer.productReplacementPageModalReplacementTypeLanguageItemName}
                                              align={DropdownAlign.Left}
                                              orderBy={DropdownOrderBy.None}
                                              minWidth={"13rem"}
                                              disabled={this.loading}
                                              items={EnumProvider.getProductReplacementTypeItems()}
                                              selectedItem={this.replacementType}
                                              onChange={(sender, item, userInteraction: boolean) => this.selectReplacementTypeFilterAsync(parseInt(item!.value), userInteraction)}
                                    />

                                    <Checkbox id={"generateLabels"}
                                              label={Localizer.productReplacementPageModalGenerateLabelsLanguageItemName}
                                              value={this.generateLabels}
                                              readonly={this.replacementType == ProductReplacementType.Permanent}
                                              onChange={(sender, value) => this.setGenerateLabels(value)}
                                    />

                                </TwoColumns>

                                <TwoColumns>

                                    <Dropdown id="replacementLevel" required noWrap
                                              label={Localizer.productReplacementPageModalLevel}
                                              align={DropdownAlign.Left}
                                              orderBy={DropdownOrderBy.None}
                                              minWidth={"13rem"}
                                              disabled={(this.loading)}
                                              items={EnumProvider.getProductReplacementLevelItems()}
                                              selectedItem={this.replacementLevel}
                                              onChange={(sender, item, userInteraction: boolean) => this.selectReplacementLevelFilterAsync(item, userInteraction)}
                                    />

                                    <Dropdown id="groupsOrCustomers" noWrap noGrouping
                                              label={this.customerGroupLabel}
                                              required={this.replacementLevel != ProductReplacementLevel.Global}
                                              requiredType={this.replacementLevel == ProductReplacementLevel.Global ? DropdownRequiredType.Restricted : DropdownRequiredType.AutoSelect}
                                              nothingSelectedText={Localizer.productReplacementPageModalAllGroupsAndCustomers}
                                              align={DropdownAlign.Left}
                                              disabled={this.loading || this.replacementLevel == ProductReplacementLevel.Global}
                                              items={this.customerItems}
                                              selectedItem={this.customerItem}
                                              orderBy={DropdownOrderBy.None}
                                              minWidth={"13rem"}
                                              transform={(item: Customer | CustomerGroup) => TransformProvider.toCustomerOrCustomerGroupSelectItem(item, styles.customerGroupItem, styles.customerItem)}
                                              onChange={(sender, item: CustomerGroup | Customer) => this.selectCustomerGroupOrCustomerAsync(item)}
                                    />

                                </TwoColumns>

                                <TwoColumns>

                                    <Checkbox id={"includeDeleted"}
                                              label={Localizer.productReplacementPageModalShowDeleted}
                                              readonly={(this.replacementType == ProductReplacementType.Temporary)}
                                              value={this.includeDeletedProducts}
                                              onChange={(sender, value) => this.setIncludeDeletedProducts(value)}
                                    />

                                    {
                                        (showDeleteGroupAssortmentsCheckbox) &&
                                        (
                                            <Checkbox id={"deleteGroupAssortments"}
                                                      label={Localizer.productReplacementPageModalDeleteAccountAssortments}
                                                      value={this.deleteGroupAssortments}
                                                      onChange={(sender, value) => this.setDeleteGroupAssortments(value)}
                                            />
                                        )
                                    }
                                    
                                </TwoColumns>

                                <TwoColumns>

                                    <Dropdown required noWrap
                                              align={DropdownAlign.Left}
                                              requiredType={DropdownRequiredType.Restricted}
                                              id={"replaceFrom"}
                                              filterMinLength={1}
                                              label={Localizer.productReplacementPageModalFrom}
                                              minWidth={"13rem"}
                                              disabled={this.loading}
                                              noDataText={Localizer.genericNoData}
                                              verticalAlign={DropdownVerticalAlign.Bottom}
                                              selectedItem={this.fromProduct || null}
                                              validators={[() => this.validateProductSelection()]}
                                              items={this.productsFrom}
                                              onChange={(sender, item) => this.setProductAsync(item, false)}
                                    />

                                    <Dropdown required noWrap
                                              align={DropdownAlign.Right}
                                              requiredType={DropdownRequiredType.Restricted}
                                              id={"replaceTo"}
                                              label={Localizer.productReplacementPageModalTo}
                                              noDataText={Localizer.genericNoData}
                                              verticalAlign={DropdownVerticalAlign.Bottom}
                                              disabled={this.loading}
                                              selectedItem={this.toProduct || null}
                                              minWidth={"13rem"}
                                              items={this.productsTo}
                                              transform={(item) => TransformProvider.toProductListItem(item!)}
                                              onChange={(sender, item: Product | null) => this.setProductAsync(item, true)}
                                    />

                                </TwoColumns>

                                <TwoColumns>

                                    <Checkbox id={"expirable"}
                                              label={Localizer.productReplacementPageModalSetExpiration}
                                              value={this.expirable}
                                              readonly={(this.replacementType == ProductReplacementType.Permanent)}
                                              onChange={(sender, value) => this.setExpirableAsync(value)}
                                    />

                                    <DateInput id={"myCalendar"}
                                               ref={this._dateInputRef}
                                               label={Localizer.productReplacementPageModalExpirationDate}
                                               title={Localizer.productReplacementPageModalSelectExpirationDate}
                                               minDate={Utility.today()}
                                               className={styles.calendar}
                                               hidden={!this.expirable}
                                               required={this.expirable}
                                               value={this.state.expirationDate ?? Utility.today()}
                                               onItemClick={(sender, date: Date) => this.setExpirationDateAsync(date)}
                                    />

                                </TwoColumns>

                                <ButtonContainer className={styles.buttons}>

                                    <Button id={"close"}
                                            type={ButtonType.Blue}
                                            label={Localizer.genericCancel}
                                            icon={{name: "far ban", size: IconSize.Large}}
                                            onClick={() => this.closeAsync()}
                                    />

                                    <Button submit
                                            id={"submitReplacement"}
                                            label={Localizer.genericSave}
                                            icon={{name: "save", size: IconSize.Large}}
                                            type={ButtonType.Orange}
                                    />

                                </ButtonContainer>

                            </Form>

                            {(this.loading) && <Spinner/>}

                        </div>
                    )
                }

            </Modal>
        )
    }
}
