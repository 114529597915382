import React from "react";
import {BaseComponent, IBaseComponentProps} from "@reapptor-apps/reapptor-react-common";
import {BorderType, Button, ColumnDefinition, Form, Grid, GridHoveringType, Modal, ModalSize} from "@reapptor-apps/reapptor-react-components";
import ProductAssortment from "@/models/server/ProductAssortment";
import Customer from "@/models/server/Customer";
import Localizer from "@/localization/Localizer";

import styles from "./ProductReplacementInfoModal.module.scss";

export interface IProductReplacementInfoModalProps extends IBaseComponentProps {
}

interface IProductReplacementInfoModalState {
    replacementId: string | null;
    loading: boolean;
}

export default class ProductReplacementInfoModal extends BaseComponent<IProductReplacementInfoModalProps, IProductReplacementInfoModalState> {

    state: IProductReplacementInfoModalState = {
        replacementId: null,
        loading: false
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _customerGridRef: React.RefObject<Grid<ProductAssortment>> = React.createRef();

    private async ResetAsync(): Promise<void> {
        await this._customerGridRef.current?.clearAsync();
        await this.setState({replacementId: null});
    }

    private async onClose(): Promise<void> {
        await this.ResetAsync();

        await this._modalRef.current!.closeAsync();
    }

    private async fetchInfoAsync(sender: Grid<ProductAssortment>): Promise<ProductAssortment[]> {
        if (!this.state.replacementId) {
            return [];
        }

        return await sender.postAsync("/api/productManagement/ListOfModifiedProductAssortmentsByReplacement", this.state.replacementId);
    }

    private readonly _columns: ColumnDefinition[] = [
        {
            name: "customerGroupName",
            header: Localizer.productReplacementPageReplacementInfoModalGridCustomerGroupLanguageItemName,
            minWidth: 400,
            maxWidth: 400,
            accessor: (model: ProductAssortment) => (model.customerGroup != null)
                ? model.customerGroup.name
                : (model.customer != null)
                    ? model.customer.customerGroup!.name
                    : "—",
        },
        {
            name: "customerName",
            header: Localizer.productReplacementPageReplacementInfoModalGridCustomerLanguageItemName,
            minWidth: 400,
            maxWidth: 400,
            accessor: (model: ProductAssortment) => (model.customer != null)
                ? Customer.getNameWithCode(model.customer)
                : "—"
        }
    ];

    public async openAsync(replacementId: string): Promise<void> {
        await this.setState({replacementId})

        await this._customerGridRef.current?.reloadAsync();
        
        await this._modalRef.current?.openAsync();
    }

    public render(): React.ReactNode {
        return (
            <Modal notResponsive
                   id={this.id}
                   ref={this._modalRef}
                   className={this.css(styles.productReplacementInfoModal, this.props.className)}
                   title={Localizer.productReplacementPageReplacementInfoModalTitle}
                   size={ModalSize.Auto}
                   onClose={() => this.onClose()}
            >

                <div>
                    <Form>

                        <Grid autoToggle responsive
                              ref={this._customerGridRef}
                              hovering={GridHoveringType.Row}
                              noDataText={Localizer.genericNoData}
                              borderType={BorderType.NoSeparators}
                              columns={this._columns}
                              fetchData={(sender: Grid<ProductAssortment>) => this.fetchInfoAsync(sender)}
                        />

                    </Form>

                </div>

            </Modal>
        )
    }
}
