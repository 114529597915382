import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Dropdown, DropdownAlign, DropdownOrderBy, Form, Panel, TextInput, TwoColumns} from "@reapptor-apps/reapptor-react-components";
import {CustomerHandler, CustomerLabelHandler, CustomerServiceType} from "@/models/Enums";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";
import AittaConstants from "@/helpers/AittaConstants";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./CustomerGeneralInformationPanel.module.scss"

interface ICustomerGeneralInformationPanelProps {
    customerGroupId?: string;
    customer: Customer;
    customerGroups: CustomerGroup[];
    onChange(sender: CustomerGeneralInformationPanel, codeModified: boolean): Promise<void>
}

interface ICustomerGeneralInformationPanelState {
}

export default class CustomerGeneralInformationPanel extends BaseComponent<ICustomerGeneralInformationPanelProps, ICustomerGeneralInformationPanelState> {

    state: ICustomerGeneralInformationPanelState = {
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();
    
    private get customer(): Customer {
        return this.props.customer;
    }

    private get customerGroups(): CustomerGroup[] {
        return this.props.customerGroups;
    }

    private get isNew(): boolean {
        return (this.customer != null) && (!this.customer.id);
    }
    
    private async invokeOnChangeAsync(codeModified: boolean = false): Promise<void> {
        await this.props.onChange(this, codeModified);
    }

    private async setCustomerGroupAsync(customerGroup: CustomerGroup, userInteraction: boolean): Promise<void> {
        this.customer.customerGroup = customerGroup;
        this.customer.customerGroupId = customerGroup.id;

        if (userInteraction) {
            await this.invokeOnChangeAsync();
        }
    }

    private async changeCustomerNameAsync(value: string): Promise<void> {
        this.customer.name = value;

        await this.invokeOnChangeAsync();
    }

    private async changeCustomerNumberAsync(code: string): Promise<void> {
        if (code != this.customer.code) {
            this.customer.code = code;

            await this.invokeOnChangeAsync(true);
        }
    }

    private async selectHandlerTypeAsync(value: CustomerHandler, userInteraction: boolean): Promise<void> {
        this.customer!.handler = value

        if (userInteraction) {
            await this.invokeOnChangeAsync();
        }
    }

    private async selectLabelHandlerTypeAsync(value: CustomerLabelHandler, userInteraction: boolean): Promise<void> {
        this.customer.labelHandler = value;

        if (userInteraction) {
            await this.invokeOnChangeAsync();
        }
    }

    private async selectServiceTypeAsync(value: CustomerServiceType, userInteraction: boolean): Promise<void> {
        this.customer.serviceType = value;

        if (userInteraction) {
            await this.invokeOnChangeAsync();
        }
    }

    private async changeCustomerReferenceAsync(value: string): Promise<void> {
        this.customer.ref = value;

        await this.invokeOnChangeAsync();
    }

    private async changeCustomerReferenceFirstNameAsync(value: string): Promise<void> {
        this.customer.refFirstName = value;

        await this.invokeOnChangeAsync();
    }

    private async changeCustomerReferenceLastNameAsync(value: string): Promise<void> {
        this.customer!.refLastName = value

        await this.invokeOnChangeAsync();
    }

    public async validateAsync(): Promise<void> {
        await this._formRef.current?.validateAsync();
    }

    public render(): React.ReactNode {
        return (
            <Panel className={this.css("flex-2", styles.customerGeneralInformationPanel)}
                   title={Localizer.editCustomerPageGeneralTitle}
            >
                
                <div className="col-12">
                    
                    {
                        <Form ref={this._formRef} className={styles.form}>

                            <TwoColumns>

                                <Dropdown id="customerGroups" required noWrap noGrouping
                                          label={Localizer.editCustomerPageGeneralCustomerGroups}
                                          minWidth={"100%"}
                                          align={DropdownAlign.Left}
                                          disabled={!this.isNew}
                                          items={this.customerGroups}
                                          selectedItem={this.customer.customerGroup || this.customer.customerGroupId}
                                          onChange={(_, item: CustomerGroup, userInteraction: boolean) => this.setCustomerGroupAsync(item, userInteraction)}
                                />

                                <TextInput required autoFocus noAutoComplete trim
                                           id="name"
                                           maxLength={AittaConstants.keyLength}
                                           label={Localizer.genericName}
                                           value={this.customer.name}
                                           onChange={(_, value) => this.changeCustomerNameAsync(value)}
                                />

                            </TwoColumns>

                            <TwoColumns>

                                <TextInput required noAutoComplete trim
                                           id="code"
                                           maxLength={AittaConstants.keyLength}
                                           label={Localizer.editCustomerPageGeneralCustomerCode}
                                           value={this.customer.code}
                                           onChange={(_, value) => this.changeCustomerNumberAsync(value)}
                                />

                            </TwoColumns>

                            <TwoColumns>

                                <Dropdown id="serviceType" required
                                          label={Localizer.editCustomerPageGeneralServiceType}
                                          align={DropdownAlign.Left}
                                          orderBy={DropdownOrderBy.None}
                                          items={EnumProvider.getCustomerServiceTypeItems()}
                                          selectedItem={this.customer.serviceType}
                                          onChange={(_, item, userInteraction: boolean) => this.selectServiceTypeAsync(parseInt(item!.value), userInteraction)}
                                />
                                
                            </TwoColumns>

                            <TwoColumns>

                                <Dropdown id="handler" required
                                          label={Localizer.editCustomerPageGeneralHandler}
                                          align={DropdownAlign.Left}
                                          orderBy={DropdownOrderBy.None}
                                          items={EnumProvider.getCustomerHandlerItems()}
                                          selectedItem={this.customer.handler}
                                          onChange={(_, item, userInteraction: boolean) => this.selectHandlerTypeAsync(parseInt(item!.value), userInteraction)}
                                />

                                <Dropdown id="labelHandler" required
                                          label={Localizer.editCustomerPageGeneralLabelHandler}
                                          align={DropdownAlign.Left}
                                          orderBy={DropdownOrderBy.None}
                                          items={EnumProvider.getCustomerLabelHandlerItems()}
                                          selectedItem={this.customer.labelHandler}
                                          onChange={(_, item, userInteraction: boolean) => this.selectLabelHandlerTypeAsync(parseInt(item!.value), userInteraction)}
                                />

                            </TwoColumns>

                            <TwoColumns>

                                <TextInput noAutoComplete
                                           id="ref"
                                           label={Localizer.editCustomerPageGeneralCustomerReference}
                                           value={this.customer.ref}
                                           onChange={(_, value) => this.changeCustomerReferenceAsync(value)}
                                />

                            </TwoColumns>

                            <TwoColumns>

                                <TextInput noAutoComplete
                                           id="ref"
                                           label={Localizer.editCustomerPageGeneralReferenceFirstName}
                                           value={this.customer.refFirstName}
                                           onChange={(_, value) => this.changeCustomerReferenceFirstNameAsync(value)}
                                />

                                <TextInput noAutoComplete
                                           id="ref"
                                           label={Localizer.editCustomerPageGeneralReferenceLastName}
                                           value={this.customer.refLastName}
                                           onChange={(_, value) => this.changeCustomerReferenceLastNameAsync(value)}
                                />

                            </TwoColumns>

                        </Form>
                    }

                </div>

            </Panel>
        );
    }
};