import React from "react";
import Customer from "@/models/server/Customer";
import {AlertModel, AlertType, BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {GeoLocation, Utility} from "@reapptor-apps/reapptor-toolkit";
import {AddressDivider, AddressDividerColumns, Alert, Checkbox, DateInput, Form, InlineType, OneColumn, Panel, TextAreaInput, TextInput, TwoColumns} from "@reapptor-apps/reapptor-react-components";
import AittaConstants from "@/helpers/AittaConstants";
import Localizer from "@/localization/Localizer";

import styles from "./CustomerContractPanel.module.scss"

interface ICustomerContractPanelProps {
    customer: Customer;

    onChange(): Promise<void>
}

interface ICustomerContractPanelState {
    isVirtualAddress: boolean;
    limitedContract: boolean;
}

export default class CustomerContractPanel extends BaseComponent<ICustomerContractPanelProps, ICustomerContractPanelState> {

    state: ICustomerContractPanelState = {
        isVirtualAddress: false,
        limitedContract: false
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();
    
    private get customer(): Customer {
        return this.props.customer;
    }

    private get isVirtualAddress(): boolean {
        return this.customer.isVirtualAddress;
    }

    private get supportsCalendar(): boolean {
        return (this.customer != null) && (Customer.supportsCalendar(this.customer));
    }
    
    private get virtualAddressWarning(): AlertModel {
        const alert = new AlertModel();
        alert.message = Localizer.editCustomerPageCustomerContractsPanelVirtualAddressWarning;
        alert.alertType = AlertType.Info;
        alert.dismissible = false;
        return alert;
    }

    private async setDeliveryStartDateAsync(value: Date): Promise<void> {
        this.customer.deliveryStartDate = value;

        await this.reRenderAsync();

        await this.onChangeAsync();
    }

    private async setDeliveryEndDateAsync(value: Date | null): Promise<void> {
        this.customer.deliveryEndDate = value;

        await this.reRenderAsync();

        await this.onChangeAsync();
    }

    private async onLocationChangeAsync(location: GeoLocation): Promise<void> {
        this.customer.virtualAddress = null;
        this.customer.location = location;

        await this.onChangeAsync();
    }

    private async onAddressDetailsChangeAsync(addressDetails: string): Promise<void> {
        this.customer.addressDetails = addressDetails;

        await this.onChangeAsync();
    }

    private async onVirtualAddressChangeAsync(location: string): Promise<void> {
        this.customer.virtualAddress = location;
        this.customer.location = null;
        this.customer.locationId = null;

        await this.onChangeAsync();
    }

    private async onVirtualAddressCheckboxChangeAsync(value: boolean) {
        this.customer.isVirtualAddress = value;

        await this.setState({isVirtualAddress: value});

        await this.onChangeAsync();
    }
    
    private async onChangeAsync(): Promise<void> {
        await this.props.onChange();
        
        await this.validateAsync();
    }
    
    public async validateAsync(): Promise<void> {
        await this._formRef.current?.validateAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public render(): React.ReactNode {

        const title: string = (this.supportsCalendar)
            ? Localizer.editCustomerPageCustomerContractsPanelTitle
            : Localizer.editCustomerPageCustomerContractsPanelExtendedTitle

        return (
            <Panel className={this.css("flex-2", styles.customerContractPanel)} title={title}>
                <div className="col-12">
                    {
                        (this.customer) &&
                        (
                            <Form ref={this._formRef} className={styles.form}>

                                {
                                    (!this.supportsCalendar) &&
                                    (
                                        <TwoColumns>

                                            <DateInput required showYearDropdown showMonthDropdown
                                                       id={"deliveryContractStartDate"}
                                                       label={Localizer.editCustomerPageCustomerContractsPanelContractStartDate}
                                                       minDate={Utility.today()}
                                                       className={styles.calendar}
                                                       value={this.customer.deliveryStartDate}
                                                       onChange={(date: Date) => this.setDeliveryStartDateAsync(date)}
                                            />

                                            <DateInput showYearDropdown showMonthDropdown clearButton
                                                       id={"deliveryContractEndDate"}
                                                       label={Localizer.editCustomerPageCustomerContractsPanelContractEndDate}
                                                       minDate={Utility.today()}
                                                       className={styles.calendar}
                                                       value={this.customer.deliveryEndDate}
                                                       onChange={(date: Date | null) => this.setDeliveryEndDateAsync(date)}
                                            />

                                        </TwoColumns>
                                    )
                                }

                                <OneColumn>

                                    <Checkbox inline
                                              inlineType={InlineType.Right}
                                              className={styles.isVirtualAddress}
                                              label={Localizer.editCustomerPageCustomerContractsPanelVirtualAddressCheckbox}
                                              value={this.customer.isVirtualAddress}
                                              onChange={(sender, item) => this.onVirtualAddressCheckboxChangeAsync(item!)}
                                    />

                                </OneColumn>

                                <OneColumn>

                                    {
                                        (this.isVirtualAddress)
                                            ?
                                            (
                                                <TextInput id="virtualAddress" required autoFocus
                                                           label={Localizer.genericAddress}
                                                           placeholder={Localizer.editCustomerPageCustomerContractsPanelVirtualAddressPlaceholder}
                                                           width={"100%"}
                                                           value={this.customer.virtualAddress || undefined}
                                                           onChange={(_, location: string) => this.onVirtualAddressChangeAsync(location)}
                                                />
                                            )
                                            :
                                            (
                                                <AddressDivider id="customerLocation" required locationPicker
                                                                columns={AddressDividerColumns.One}
                                                                location={this.customer.location || undefined}
                                                                onChange={(_, location) => this.onLocationChangeAsync(location)}
                                                />
                                            )
                                    }

                                    {
                                        (this.isVirtualAddress)
                                            ?
                                            (
                                                <Alert model={this.virtualAddressWarning} />
                                            )
                                            :
                                            (
                                                <TextAreaInput maxLength={AittaConstants.keyLength}
                                                               rows={2}
                                                               label={Localizer.editCustomerPageCustomerContractsPanelAddressDetailsLabel}
                                                               placeholder={Localizer.editCustomerPageCustomerContractsPanelAddressDetailsPlaceholder}
                                                               value={this.customer.addressDetails}
                                                               onChange={(_, value) => this.onAddressDetailsChangeAsync(value)}
                                                />
                                            )
                                    }

                                </OneColumn>

                            </Form>
                        )
                    }

                </div>

            </Panel>
        );
    }
};